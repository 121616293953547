'use client';
import { FC, useEffect, useState } from 'react';
import styles from './NavBar.module.scss';
import Image from 'next/image';
import Logo from '../../app/assets/icons/logo.svg';
import { useAppSelector } from '@/app/redux/hooks';
import Link from 'next-intl/link';
import { useTranslations } from 'next-intl';
import ButtonElement from '../ButtonElement';
import user from '../../app/assets/icons/person.svg';
import mountain from '../../app/assets/icons/mountain.svg';
import Letter from '../../app/assets/icons/letter.svg';
import Twitter from '../../app/assets/icons/twitter.svg';
import Telegram from '../../app/assets/icons/telegram-2.svg';
import AuthPopup from '../AuthPopup';
import WaitlistPopupContainer from "@/shared/WaitlistPopupContainer";

interface NavBarProps {
  className?: string;
  isMobile?: boolean;
}

const NavBar: FC<NavBarProps> = ({ className = '', isMobile = false }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const { isOpenMenu } = useAppSelector((state) => state.authorization);
  const t = useTranslations('BURGER_MENU');
  const ft = useTranslations('FOOTER_COMPONENT');
  const [token, setToken] = useState<string>('')
  const [waitlistPopupOpen, setWaitlistPopupOpen] = useState<boolean>(false);

  useEffect(() => {
    const _token = localStorage.getItem('token') || ''
    setToken(_token)
    setIsAuthorized(!!_token);
  }, []);

  const handleAuthorize = () => {
    if (!token) {
      setIsOpen(true);
    } else {
      localStorage.clear();
    }
  };

  const year = new Date().getFullYear();

  return (
    <aside
      className={`${styles.root} ${
        isOpenMenu && isMobile ? styles.root__open : styles.root__closed
      } ${className}`}>
      <Link prefetch={false} className={styles['navbar__logo']} href="/">
        <Image src={Logo} alt="logo" />
      </Link>

      <nav className={styles['navbar__links']}>
        <div className={styles['links__container']}>
          <ButtonElement onClick={handleAuthorize} className={styles.signin} text={'sign in'}>
            <span className={styles.signin__icon}>
              <Image src={user} alt="user" />
            </span>
            <p>{token ? 'Sign out' : 'Sign in'}</p>
          </ButtonElement>
        </div>

        {/*<div className={styles['links__container']}>*/}
        {/*  <b className={styles['links__title']}>EVENTS</b>*/}
        {/*  <Link className={styles['links__elem']} href="/">*/}
        {/*    Concerts*/}
        {/*  </Link>*/}
        {/*  <Link className={styles['links__elem']} href="/">*/}
        {/*    Meetups*/}
        {/*  </Link>*/}
        {/*  <Link className={styles['links__elem']} href="/">*/}
        {/*    Forums*/}
        {/*  </Link>*/}
        {/*</div>*/}

        {/*<div className={styles['links__container']}>*/}
        {/*  <b className={styles['links__title']}>OTHER</b>*/}
        {/*  <Link className={styles['links__elem']} href="/">*/}
        {/*    About*/}
        {/*  </Link>*/}
        {/*  <Link className={styles['links__elem']} href="/">*/}
        {/*    Sell tickets*/}
        {/*  </Link>*/}
        {/*  <Link className={styles['links__elem']} href="/">*/}
        {/*    Client offer*/}
        {/*  </Link>*/}
        {/*  <Link className={styles['links__elem']} href="/">*/}
        {/*    Refund*/}
        {/*  </Link>*/}
        {/*  <Link className={styles['links__elem']} href="/">*/}
        {/*    Terms & Privacy*/}
        {/*  </Link>*/}
        {/*</div>*/}

        <div className={styles['links__container']}>
          <ButtonElement color="dark" className={styles.organizer} text={'sign in'} onClick={() => setWaitlistPopupOpen(true)}>
            <span className={styles.signin__icon}>
              <Image src={mountain} alt="mountain" />
            </span>
            Organizers room
          </ButtonElement>
        </div>

        <div className={styles.social}>
          <a className={styles.social__link} target="_blank" href="https://t.me/gligleapp">
            <Image src={Telegram} alt="telegram" />
          </a>
          <a className={styles.social__link} target="_blank" href="https://twitter.com/gligleapp">
            <Image src={Twitter} alt="Twitter" />
          </a>
          <a
            className={`${styles.social__link} ${styles.social__letter}`}
            target="_blank"
            href="mailto:support@gligle.app">
            <Image src={Letter} alt="Letter" />
          </a>
        </div>

        <footer className={styles.footer}>
          <b className={styles.text__bold}>Gligle, 2022-{year}</b>
          <p className={styles.text__default}>
            {ft('use')}<br/>
            <Link href={'/public-offer'}>{ft('public_offer')}</Link>
          </p>
        </footer>
      </nav>

      <AuthPopup setIsOpen={() => setIsOpen(false)} woRedirect isOpen={isOpen}/>
      <WaitlistPopupContainer
        isOpen={waitlistPopupOpen}
        setIsOpen={setWaitlistPopupOpen}
      />
    </aside>
  );
};

export default NavBar;
